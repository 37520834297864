import debounce from 'lodash/debounce';
import React, { useMemo, useState, useEffect } from 'react';

export default function CustomFilter({ id = '', title = '', name = '', options = [], onChange, type: filterType }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([...options]);

  const handleChange = e => {
    setSearchQuery(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    setSearchQuery("")
    if (document.getElementById("options-input")) {
      document.getElementById("options-input").value = ""
    }
  }, [options])

  useEffect(() => {
    setFilteredOptions(options.filter(item => item?.value.toLowerCase().startsWith(searchQuery.toLowerCase())));
  }, [options, searchQuery]);

  return (
    <div className="filter-dropdown" id={id}>
      <div className="f-search-parent">
        {filterType === 'search' && (title.toLowerCase() === 'categories' || title.toLowerCase() === 'brands') && (
          <div className="mt-1 search-input">
            <i className="icon-search" />
            <input id='options-input' className="form-control mb-2 mt-1" placeholder={`Search ${title}`} onChange={debouncedResults} />
          </div>
        )}
      </div>
      <div className={`filters-dropdown-list ${filterType}`}>
        {filteredOptions.map(({ checked, type, value, count }) => {
          return (
            <div className={`custom-${type} mt-2 d-flex justify-content-between pr-2`}>
              <div>
                <input checked={checked} type={type} id={value} name={name} value={value} onChange={e => onChange(e, type)} />
                <label htmlFor={value} className="">
                  {value}
                </label>
              </div>
              {count ? <span className='ml-auto'>{count}</span> : null}
              <br />
            </div>
          );
        })}
      </div>
    </div>
  );
}
