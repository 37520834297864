import React from 'react';
import { isNewHomePageApplicable } from '../constants/feature';

function FloatingFilterButton({ filters, toggleFilterPopup, filterApplied, isSolid, className, isSearchPage }) {
  return (
    <>
      {filters && filters.length > 0 || isSearchPage ? (
        <>
          <div
            test-id="#float-filter"
            className={`filter-btn fab float-filter ${!isSolid ? 'scroll-float' : ''
              } hide-for-kiva hide-for-drinkcann hide-for-plus-products ${className}`}
            role="presentation"
            onClick={() => toggleFilterPopup(true)}
          >
            <span className={`icon ${isNewHomePageApplicable ? "icon-filter-dark-i" : "icon-filter"}`} />
            <span className="expand-btn-text">Filter and Sort</span>
            {filterApplied && <span className="filter-indicator" />}
          </div>
        </>
      ) : null}
    </>
  );
}
export default FloatingFilterButton;
